export default {
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  UP: 38,
  DOWN: 40,
  HOME: 35,
  SPACE: 32,
  BACKSPACE: 8,
  END: 35,
  SPACE: 32,
  DELETE: 46,
  RETURN: 13,
  ESC: 27,
}
